import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';

const CustomModal = ({ open, onClose, title, children }) => {
    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    height:{
                        xs:'auto'
            
                    },
                    width:{
                        xs:'80%',
                        md:'400px'
                    },
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 5,
                    borderRadius: 2,
                    
                }}
            >
                <Typography variant="h4" component="h2"  color="success.main">
                    {title}
                </Typography>
                <Box sx={{
                    maxHeight: '70vh',
                    overflowY: 'auto', // Habilita el desplazamiento vertical
                }}>{children}</Box>
                <Button onClick={onClose}  variant="contained" >
                    Close
                </Button>
            </Box>
        </Modal>
    );
};

export default CustomModal;
