import React from 'react';
import { Box, Grid2 } from '@mui/material';

const LogoRow = ({ logos }) => {
  return (
    <Box sx={{ width: '100%', overflowX: 'auto', padding: '16px 0' }}>
      <Grid2 container spacing={2} sx={{ display: 'flex', justifyContent: 'center' }}>
        {logos.map((logo, index) => (
          <Grid2 item key={index} xs={12} sm={6} md={2} sx={{ textAlign: 'center' }}>
            <img src={logo.src} alt={logo.alt} style={{ width: '250px', height: '200px', objectFit: 'contain' }} />
          </Grid2>
        ))}
      </Grid2>
    </Box>
  );
};
const LogoSection = () => {
  // Datos de los logos que queremos mostrar
  const logos1 = [
    { src: 'img/logos/flor_aragua.jpeg', alt: 'Flor de Aragua' },
    { src: 'img/logos/avicola_providencia.jpg', alt: 'Avicola Providencia' },
    { src: 'img/logos/moore.jpg', alt: 'Moore' },
    { src: 'img/logos/sura_de_venezuela_c_a_logo.jpeg', alt: 'Sura de Venezuela C.A Logo' },
    { src: 'img/logos/asnca.png', alt: 'ASNCA' },
    { src: 'img/logos/ovomar-featured.jpg', alt: 'Ovomar Featured' },
  ];

  const logos2 = [
    { src: 'img/logos/danimex.png', alt: 'Danimex' },
    { src: 'img/logos/logo_venefoil.png', alt: 'Logo Venefoil' },

    { src: 'img/logos/EmpresasPolar2010.png', alt: 'Empresas Polar' },
    { src: 'img/logos/bridgestone.png', alt: 'Bridgestone' },
    { src: 'img/logos/celoven.jpg', alt: 'Celoven' },
  ];

  const logos3 = [
    { src: 'img/logos/johnson-family.jpg', alt: 'Johnson Family' },
    { src: 'img/logos/logo_general_mills_antes.jpg', alt: 'Logo General Mills Antes' },
    { src: 'img/logos/punta_larga.png', alt: 'Punta Larga' },
    { src: 'img/logos/Mampa_Logo.png', alt: 'Mampa Logo' },
    { src: 'img/logos/logodestileria-1.png', alt: 'Logo Destilería' },
  ];

  return (
    <Box sx={{ backgroundColor: 'white', padding: 2 }}>
      {/* Sección de los logos */}
      <LogoRow logos={logos1} />
      <LogoRow logos={logos2} />
      <LogoRow logos={logos3} />
    </Box>
  );
};

export default LogoSection;
