import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { CardMedia } from "@mui/material";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Carousel = () => {
  const [images, setImages] = useState([
    "img/publicidad/slice1.jpg",
    "img/publicidad/slice2.jpg",
    "img/publicidad/slice3.jpg"
  ]);

  // Verifica si una imagen existe en el servidor
  const checkImageExists = async (url) => {
    const img = new Image();
    img.src = url;
    return new Promise((resolve) => {
      img.onload = () => resolve(true); // Si la imagen carga, es válida
      img.onerror = () => resolve(false); // Si hay un error, la imagen no existe
    });
  };

  useEffect(() => {
    const fetchImages = async () => {
      const validImages = await Promise.all(
        images.map(async (image) => {
          const exists = await checkImageExists(image);
          return exists ? image : null;
        })
      );
      setImages(validImages.filter(Boolean)); // Filtra las imágenes que existen
    };

    fetchImages();
  }, []);

  const settings = {
    dots: true, // Muestra los puntos de navegación
    infinite: true, // Carrusel infinito
    speed: 500, // Velocidad de transición
    slidesToShow: 1, // Número de slides que se muestran al mismo tiempo
    slidesToScroll: 1, // Número de slides que se desplazan al hacer clic
    nextArrow: <CustomArrow direction="next" />,
    prevArrow: <CustomArrow direction="prev" />,
  };

  return (
    <div style={{ maxWidth: "1000px", margin: "0 auto" }}>
      {images.length > 0 && (
        <Slider {...settings}>
          {images.map((image, index) => (
            <div key={index} style={{display: 'flex',  alignItems: 'center'}}>
              <CardMedia
                component="img"
                sx={{
                  width: "100%",
                  height: "750px",
                  objectFit: 'contain',
        
                }}
                image={image}
                alt={`Slide ${index + 1}`}
              />
            </div>
          ))}
        </Slider>
      )}
    </div>
  );
};

const CustomArrow = ({ className, style, onClick }) => (
  <div
    className={className}
    style={{
      ...style,
      backgroundColor: "rgba(0, 0, 0, 0.2)",
      borderRadius: "50%",
      padding: "5px",
      opacity: 0.5,
    }}
    onClick={onClick}
  />
);

export default Carousel;
