import React from 'react';
import { Card, CardMedia, CardContent, Box, Typography } from '@mui/material';

const TeamMemberCard = ({ name, image }) => {
  return (
    <Card sx={{ 
        width: '100%', 
        textAlign: 'center', 
        border: 'none', // Elimina el borde
        boxShadow: 'none', // Elimina la sombra
        }}>
      {/* Imagen en la parte superior */}
      <CardMedia
        component="img"
        alt={name}
        height="400"
        image={image}
        sx={{ objectFit: 'cover' }}
      />
      
      {/* Cuerpo de la tarjeta con el nombre */}
      <CardContent>
        <Box
          sx={{
            backgroundColor: 'success.main', // Fondo verde
            color: 'white', // Texto en blanco
            padding: '8px 16px',
            borderRadius: '8px', // Bordes redondeados
            display: 'inline-block',
          }}
        >
          <Typography variant="body1" sx={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>
            {name}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default TeamMemberCard;
