import React, { useState, useEffect} from 'react';
import { Box, Card, CardContent, CardMedia, Button, Grid2, Typography } from '@mui/material';

import CustomModal from './customModal';

const ServicesSection = () => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [services, setServices] = useState([])

  // Función para abrir el modal con la información del servicio
  const handleOpenModal = (service) => {
    setSelectedService(service);
    setOpenModal(true);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/services.json'); // Ruta al JSON en la carpeta `public`
        if (!response.ok) {
          throw new Error('Error al cargar el archivo JSON');
        }
        const jsonData = await response.json();
        setServices(jsonData);
      } catch (error) {
        console.error('Error al cargar el archivo JSON:', error);
      }
    };

    fetchData();
  }, []);

  const handleCloseModal = () => setOpenModal(false);

  

  return (
    <Box>
      {services.map((service, index) => (
        <Card key={index} sx={{
          mb: 3,
          border: 'none', // Elimina el borde
          boxShadow: 'none', // Elimina la sombra
          backgroundColor: 'transparent'
        }}>
          <Grid2 container spacing={{ xs: 1, md: 24 }} justifyContent="center"
            sx={{
              flexDirection: index % 2 !== 0 ? 'row-reverse' : 'row', // Esto invierte las posiciones de los elementos
              alignItems: 'center',

            }}>
            <Grid2 item xs={12} sm={6} md={6}>
              <CardMedia
                component="img"
                alt={service.title}
                image={service.img}
                sx={{ width: '90%', minWidth: '250px' }}
              />
            </Grid2>
            <Grid2 item xs={12} sm={6} md={4}>
              <CardContent >
                <Typography variant="h4" color="secondary" sx={{
                  mb: 1,
                  whiteSpace: 'pre-line', // Esto hace que los saltos de línea sean visibles

                  textAlign: 'start',
                  fontSize: {
                    xs: '2.5rem', // Tamaño para pantallas extra pequeñas
                    sm: '2.5rem',   // Tamaño para pantallas pequeñas
                    md: '2.5rem', // Tamaño para pantallas medianas
                    lg: '3rem',   // Tamaño para pantallas grandes
                  },
            

                  position: 'relative',
                  '::after': {
                    content: '""',
                    position: 'absolute',
                    bottom: -3, // Mueve la línea justo debajo del texto
                    left: '0%', // Empuja la línea hacia el centro del texto
                    transform: 'translate(-0%, 10px)', // Centra la línea con el texto
                    width: '30%', // La línea tiene el 50% del ancho del texto
                    height: '7px', // Grosor de la línea
                    backgroundColor: 'green', // Color de la línea
                  },
                }}>
                  {service.title}
                </Typography>
                <br />


                <Typography variant="h5" color="primary" sx={{
                  mb: 2,
                  whiteSpace: 'pre-line', // Esto hace que los saltos de línea sean visibles

                  textAlign: 'start'
                }}>
                  {service.description}
                </Typography>
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => handleOpenModal(service)}
                  fullWidth
                >
                  Saber más
                </Button>
              </CardContent>
            </Grid2>
          </Grid2>
        </Card>
      ))}

      {/* Modal de Detalles del Servicio */}
      <CustomModal open={openModal} onClose={handleCloseModal} title={selectedService?.title}>

        <img
          src={selectedService?.img}
          alt={selectedService?.title}
          style={{ width: '100%' }}
        />

        {
          selectedService?.subElements.map((service, index) => (
            <Box>
              <Typography variant='h6' color="success.main" >
                {service.title}
              </Typography>
              <Typography variant='body1'>
                {service.description}
              </Typography>
              <ul>
                {service?.details.map((details, index) => (
                  <li>
                    <Typography variant='body1'>
                      {details}
                    </Typography>
                  </li>

                ))}
              </ul>

            </Box>

          )
          )
        }

      </CustomModal>


      {/* Imagen de Infografía */}
      <Grid2 container justifyContent="center">
        <Grid2 item xs={12} sm={8}>
          <Box component="img" src="img/Infografia.png" alt="Infografía" sx={{ width: '100%', borderRadius: 2 }} />
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default ServicesSection;
