import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import theme from './theme/theme';  // Importamos el tema desde theme.js

const App = () => {

  return (
    <ThemeProvider theme={theme}>  {/* Aplicamos el tema personalizado */}
      <div>
        <Header />  {/* Componente Header */}
        
        <main>
          {/* Componente Home: contiene el contenido principal de la página */}
          <Home />


        </main>

        <Footer />  {/* Componente Footer */}

        {/* Componente Modal para mostrar más detalles sobre el servicio */}
       
      </div>
    </ThemeProvider>
  );
};

export default App;
