import React, { useState } from 'react';
import { Button, Box, Typography, Alert } from '@mui/material';
import FormTextField from './FormTextField';
import { enviarFormulario } from './formService';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    lastname: '',
    email: '',
    phone: '',
    message: '',
  });

  const [errors, setErrors] = useState({}); // Manejar errores

  const handleChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });

    // Limpiar errores al cambiar el campo
    if (errors[name]) {
      setErrors({ ...errors, [name]: null });
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name.trim()) newErrors.name = 'El nombre es obligatorio';
    if (!formData.lastname.trim()) newErrors.lastname = 'El apellido es obligatorio';
    if (!formData.phone.trim() && !formData.email.trim()) {
      newErrors.phone = 'Debe ingresar al menos un teléfono o un correo';
      newErrors.email = 'Debe ingresar al menos un teléfono o un correo';
    }
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validar formulario
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    // Enviar formulario si no hay errores
    const success = await enviarFormulario(formData);
    if (success) {
      alert('Formulario enviado exitosamente');
      setFormData({ name: '', lastname: '', email: '', phone: '', message: '' }); // Reiniciar
    } else {
      alert('Hubo un error al enviar el formulario');
    }
  };

  return (
    <Box sx={{ padding: '2em', backgroundColor: 'secondary.main', borderRadius: 2, alignContent: 'center' }}>
      <Typography
        variant="h3"
        color="white"
        sx={{
          fontWeight: 'bold',
          textAlign: 'left',
          paddingLeft: 2,
          marginBottom: 2,
        }}
      >
        Conecta con nosotros
      </Typography>
      <Typography
        variant="h3"
        color="white"
        sx={{
          fontWeight: 'bold',
          textAlign: 'left',
          paddingLeft: 2,
          marginBottom: 2,
        }}
      >
        y descubre más.
      </Typography>

      <form onSubmit={handleSubmit}>
        <FormTextField
          label="Nombre"
          name="name"
          value={formData.name}
          onChange={handleChange}
          error={!!errors.name} // Mostrar error si existe
          helperText={errors.name} // Mensaje de error
        />
        <FormTextField
          label="Apellido"
          name="lastname"
          value={formData.lastname}
          onChange={handleChange}
          error={!!errors.lastname}
          helperText={errors.lastname}
        />
        <FormTextField
          label="Email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          error={!!errors.email}
          helperText={errors.email}
          type="email"
        />
        <FormTextField
          label="Teléfono"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          error={!!errors.phone}
          helperText={errors.phone}
          type="tel"
        />
        <FormTextField
          label="Mensaje"
          name="message"
          value={formData.message}
          onChange={handleChange}
          multiline
          minRows={4}
        />

        <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
          Enviar
        </Button>
      </form>
    </Box>
  );
};

export default ContactForm;

