import React from 'react';
import { Box, Grid2, Typography, IconButton } from '@mui/material';
import { Facebook, Instagram, LinkedIn } from '@mui/icons-material'; // Iconos de MUI
import logoDimg from '../img/logoD.png'

const Footer = () => {
  return (
    <Box sx={{ backgroundColor: 'success.main', color: 'white', padding: '5%',}}>
      <Grid2 container spacing={{sx:2, md:12}} justifyContent="center" alignItems="center">
        {/* Columna para el logo principal */}
        <Grid2 item xs={12} sm={6} md={3} textAlign="center">
          <img src={logoDimg} alt="logoD" style={{ maxWidth: '100%', height: 'auto' }} />
        </Grid2>

        {/* Columna para los cinco logos más pequeños */}
        <Grid2 item xs={12} sm={6} md={6} container justifyContent="center" spacing={2}>
          <Grid2 item>
            <a href="https://www.facebook.com/DyDconsult/" target="_blank" rel="noopener noreferrer">
              <IconButton color="background">
                <Facebook sx={{ fontSize: 40 }} />
              </IconButton>
            </a>
          </Grid2>
          <Grid2 item>
            <a href="https://www.instagram.com/dydconsultalento?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" target="_blank" rel="noopener noreferrer">
              <IconButton color="background">
                <Instagram sx={{ fontSize: 40 }} />
              </IconButton>
            </a>
          </Grid2>
          <Grid2 item position="relative">
            <a href="https://www.linkedin.com/in/mar%C3%ADa-dolores-mart%C3%ADnez-abarca-6a48407b/" target="_blank" rel="noopener noreferrer">
              <IconButton color="background">
                <LinkedIn sx={{ fontSize: 40 }} />
              </IconButton>
            </a>
            <Typography variant="caption" sx={{ position: 'absolute', top: 0, right: 0, backgroundColor: 'primary.background', color: 'white', borderRadius: '50%' }}>
              Lola
            </Typography>
          </Grid2>
          <Grid2 item position="relative">
            <a href="https://www.linkedin.com/in/saida-y-campos-r-02046a76/" target="_blank" rel="noopener noreferrer">
              <IconButton color="background">
                <LinkedIn sx={{ fontSize: 40 }} />
              </IconButton>
            </a>
            <Typography variant="caption" sx={{ position: 'absolute', top: 0, right: 0, backgroundColor: 'primary.background', color: 'white', borderRadius: '50%' }}>
              Saida
            </Typography>
          </Grid2>
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default Footer;
