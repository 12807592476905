import React from 'react';
import { TextField } from '@mui/material';

const FormTextField = ({ label, name, value, onChange, type = 'text', multiline = false, minRows = 1, error, helperText }) => {
  const handleChange = (e) => {
    onChange(name, e.target.value); // Llama a la función `onChange` del padre
  };

  return (
    <TextField
      label={label}
      name={name}
      value={value}
      onChange={handleChange}
      fullWidth
      margin="normal"
      variant="outlined"
      type={type}
      multiline={multiline}
      minRows={minRows}
      error={error} // Propiedad para mostrar error visual
      helperText={helperText} // Muestra el mensaje de error
    />
  );
};

export default FormTextField;
