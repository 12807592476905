import { createTheme } from '@mui/material/styles';

// Crear el tema personalizado
const theme = createTheme({
  palette: {
    primary: {
      main: '#1a6d37', // Verde principal
    },
    secondary: {
      main: '#5eb646', // Tono secundario
    },
    background: {
      default: '#f4f4f4', // Fondo general
    },
    text: {
      primary: '#fff', // Texto principal en blanco
      secondary: '#fff', // Texto secundario en blanco
    },
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'outlined', // Variante predeterminada
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#fff', // Borde blanco por defecto
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#fff', // Borde blanco al hacer hover
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#fff', // Borde blanco cuando está enfocado
          },
          '& .MuiInputBase-input': {
            color: '#fff', // Texto dentro del campo en blanco
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#fff', // Label en blanco por defecto
          '&.Mui-focused': {
            color: '#fff', // Label en blanco cuando está enfocado
          },
        },
      },
    },
  },
});

export default theme;
