import React from 'react';
import { Box, Card, CardContent, CardMedia, Grid2, Typography } from '@mui/material';

const ServiceCards = () => {
  const services = [
    {
      title: 'Servicio al Cliente',
      image: 'img/Icono1.png',
      alt: 'servicio al cliente',
    },
    {
      title: 'Aprendizaje Permanente',
      image: 'img/Icono2.png',
      alt: 'Aprendizaje Permanente',
    },
    {
      title: 'Innovación',
      image: 'img/Icono3.png',
      alt: 'Innovación',
    },
    {
      title: 'Ética personal/profesional',
      image: 'img/Icono4.png',
      alt: 'Ética personal/profesional',
    },
    {
      title: 'Compromiso',
      image: 'img/Icono5.png',
      alt: 'Compromiso',
    },
  ];

  return (
    <Box sx={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
      <Grid2 container spacing={2} justifyContent="center" sx={{ }}>
        {services.map((service, index) => (
          <Grid2 item xs={12} sm={5} md={6} lg={2} key={index}>
            <Card sx={{
              display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%',
              border: 'none', // Elimina el borde
              boxShadow: 'none', // Elimina la sombra
            }}>
              <CardMedia
                component="img"
                alt={service.alt}
                image={service.image}
                sx={{ width: '100px', borderRadius: 1 }}
              />
              <CardContent sx={{ textAlign: 'center' }}>
                <Typography variant="h6" sx={{ color: 'success.main', fontWeight: 'bold' }}>
                  {service.title}
                </Typography>
              </CardContent>
            </Card>
          </Grid2>
        ))}
      </Grid2>
    </Box>
  );
};

export default ServiceCards;
