import React from 'react';
import lolaImg from '../img/lola.png'
import saidaImg from '../img/Saida.png'
import { Grid2, Box, Typography } from '@mui/material';
import TeamMemberCard from '../components/TeamMemberCard'; // Importamos el nuevo componente
import ContactForm from '../components/ContactForm';
import LogoSection from '../components/LogoRow';
import ServicesSection from '../components/ServicesSection';
import ServiceCards from '../components/ServiceCards';
import seccion1img from '../img/seccion1.jpg'
import seccion2Img from '../img/seccion2.jpg'
import Carousel from '../components/Carrusel';
const Home = () => {
	const teamMembers = [
		{ name: 'Lola Martínez-Abarca', image: lolaImg },
		{ name: 'Saida Campos Riera', image: saidaImg },
	];

	return (
		<Box sx={{ width: '100%' }} >

			{/* Sección ¿Quiénes Somos? */}
			<Box sx={{ my: 6, textAlign: 'center' }}>
				<Typography variant="h3" color="primary" sx={{ fontWeight: 'bold' }}>
					¿Quiénes Somos?
				</Typography>
				<Grid2 container spacing={12} justifyContent="center" sx={{ my: 4 }}>
					{teamMembers.map((member, index) => (
						<Grid2 item key={index} xs={12} sm={6} md={4}>
							{/* Usamos el componente TeamMemberCard */}
							<TeamMemberCard name={member.name} image={member.image} />
						</Grid2>
					))}
				</Grid2>
				<br />
				<Typography variant="h3" color="gray" sx={{ fontWeight: 'bold' }}>
					Somos un equipo interdisciplinario integrado por profesionales que comparten una filosofía centrada en Valores de:
				</Typography>
				<br />
				<br />
				<ServiceCards />
			</Box>

			<Box sx={{
				textAlign: 'center',
				backgroundImage: `url(${seccion1img})`, // Usamos la imagen importada
				backgroundSize: 'cover', // Asegura que la imagen cubra todo el contenedor
				backgroundPosition: 'center', // Centra la imagen en el contenedor
				backgroundRepeat: 'no-repeat', // Evita la repetición de la imagen
				padding: '10%',
			}}>
				<Typography variant="h3" color="primary" sx={{ fontWeight: 'bold' }}>
					Nuestros Servicios
				</Typography>
				<ServicesSection />
			</Box>
			<Box sx={{
				textAlign: 'center',
				backgroundImage: `url(${seccion2Img})`, // Usamos la imagen importada
				backgroundSize: 'cover', // Asegura que la imagen cubra todo el contenedor
				backgroundPosition: 'center', // Centra la imagen en el contenedor
				backgroundRepeat: 'no-repeat', // Evita la repetición de la imagen

				padding: '5%',
			}}>
				<Grid2 container spacing={12} sx={{
					my: 2,
					justifyContent: 'center',
					alignItems: 'center'
				}}
					alignItems="center"  >
					<Grid2 size={{ xs: 12, md: 6 }}>
						<ContactForm /> 
  					</Grid2>
					<Grid2 size={{ xs: 12, md: 6 }}>
						<Carousel />
					
					</Grid2>

				</Grid2>


			</Box>
			<LogoSection />
		</Box>
	);
};

export default Home;
