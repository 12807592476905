import React from 'react';
import { Box,  Typography, useTheme  } from '@mui/material';
import logoDimg from '../img/logoD.png'
import header1Img from '../img/Header.png'

const Header = () => {
  const theme = useTheme(); // Usamos el hook useTheme para acceder al tema

  return (
    <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      background: ` url(${header1Img}), linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
      backgroundSize: 'cover', // Asegura que la imagen cubra todo el contenedor
      backgroundRepeat: 'no-repeat', // Evita que la imagen se repita
      backgroundPosition: 'right bottom', // Posiciona la imagen a la derecha y al fondo
      minHeight: '300px', // Tamaño mínimo para el contenedor
      color: 'white', // Texto blanco
      textAlign: 'left',
      padding: '5em',
      [theme.breakpoints.down('sm')]: {
        padding: '3em',
        background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`, // Solo el gradiente en pantallas pequeñas
      },
      
    }}
    >

      <Box
      >
        <Box
          component="img"
          src={logoDimg} // Usamos la misma imagen en la derecha (ajústalo a tu necesidad)
          alt="imagen"
          sx={{
            width: '30%',
            minWidth: 300,
            maxWidth: 500,
            mb:2
          }}
        />
        <Typography variant="h1" sx={{
          fontWeight: 'bold', fontSize: '7vw'
        }}>
          Generando
          <br />
          Resultados
          <br />
          a través de la gente.
        </Typography>
        <br />
    
      </Box>



    </Box>
  );
};

export default Header;
